import { useContext } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ShoppingListDispatchContext, AppContext } from "../App";


export default function ShoppingListView() {

    const { shoppingListState, editable } = useContext(AppContext);
    const dispatch = useContext(ShoppingListDispatchContext);

    function handleDeleteBtnClick(e: React.MouseEvent<HTMLButtonElement>, id: number) {
        e.stopPropagation();
        dispatch({ type: 'deleteItem', id });
    }

    return (
        <>
            <ul className="flex flex-col-reverse gap-1" aria-label="Your shopping list.">
                {shoppingListState.map((shoppingItem) =>
                    <li
                        key={shoppingItem.id}
                        onClick={() => dispatch({ type: 'togglePurchased', id: shoppingItem.id })}
                        className={`bg-slate-200 shadow-sm font-semibold cursor-pointer px-2 py-4 flex justify-between  ${shoppingItem.purchased ? 'line-through bg-slate-50' : ''}`}
   
                    >
                        <span>{shoppingItem.text}</span>
                        {editable &&
                            <button
                                onClick={(e) => handleDeleteBtnClick(e, shoppingItem.id)}
                                aria-label="Button to remove an item from your shopping list."
                            >
                                {/* Delete icon */}
                                <RiDeleteBin6Line style={{color: '#dc2626', fontSize: '24px'}}  />

                            </button>

                        }
                    </li>
                )}
            </ul>
        </>
    );
}