import { useContext, useRef, useState } from "react";
import { ShoppingListDispatchContext } from "../App";



export default function AddItemPanel() {

    const dispatch = useContext(ShoppingListDispatchContext);
    const [inputText, setInputText] = useState("");
    const textInputElement = useRef<HTMLInputElement>(null); 

    function handleAddItemClick() {
        dispatch({ type: 'addItem', itemText: inputText });
        setInputText('');
        textInputElement.current?.focus();

    
    }

    function handleInputKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            handleAddItemClick();
        }
    }

    return (
        <div>
            <input
                type="text"
                className="border border-sky-400 pl-2 py-1"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => handleInputKeyUp(e)}
                aria-label="Input for the text of the new item to be added to your shopping list."
                ref={textInputElement}

            />
            <button
                className="border border-sky-400 py-1 px-2 ml-2 rounded-xl"
                onClick={handleAddItemClick}
                aria-label="Button to add new item to your shopping list."
            >Add Item
            </button>
        </div>
    );
}