import { TwitterShareButton, XIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';


export default function SocialMediaLinks() {


    return (
        <div className='flex gap-1'>

            <TwitterShareButton url='https://onlineshoppinglist.pages.dev/'><XIcon size={30} round={true}  /></TwitterShareButton>
            <FacebookShareButton url='https://onlineshoppinglist.pages.dev/'><FacebookIcon size={30} round={true}  /></FacebookShareButton>
            <WhatsappShareButton url='https://onlineshoppinglist.pages.dev/' title='Online Shopping List'><WhatsappIcon size={30} round={true}  /></WhatsappShareButton>
        </div>
    );
}